<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="企业客户信息">
          <van-cell-group title="企业信息">
            <van-cell title="企业全称" :value="utils.isEffectiveCommon(accessDetail.orgName)" />
            <van-cell title="企业简称" :value="utils.isEffectiveCommon(accessDetail.orgShortName)" />
            <van-cell title="成立日期" :value="utils.isEffectiveCommon(accessDetail.establishDate)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(accessDetail.orgNo)" />
            <van-cell title="注册资本（万元）" :value="utils.moneyFormat(accessDetail.orgRegCap)" />
            <van-cell title="企业联系电话" :value="utils.isEffectiveCommon(accessDetail.orgContactPhone)" />
            <van-cell title="注册地址" :value="utils.newProvinceCityFormatter([accessDetail.orgRegProvince,accessDetail.orgRegCity, accessDetail.orgRegXq, accessDetail.orgRegDetail])" />
            <van-cell title="办公地址" :value="utils.newProvinceCityFormatter([accessDetail.orgWorkProvince,accessDetail.orgWorkCity, accessDetail.orgWorkXq, accessDetail.orgWorkDetail])" />
          </van-cell-group>
          <van-cell-group title="企业法定代表人信息">
            <van-cell title="法定代表人姓名" :value="utils.isEffectiveCommon(accessDetail.businessName)" />
            <van-cell title="证件类型" :value="utils.statusFormat(accessDetail.businessSnType, 'typeCertificateType')" />
            <van-cell title="电子邮箱" :value="utils.isEffectiveCommon(accessDetail.businessEmail)" />
            <van-cell title="法定代表人联系电话" :value="utils.isEffectiveCommon(accessDetail.bizPhone)" />
            <van-cell title="证件号码" :value="utils.isEffectiveCommon(accessDetail.businessSn)" />
            <van-cell title="企业录入人" :value="utils.isEffectiveCommon()" />
          </van-cell-group>
          <van-cell-group title="账户余额">
            <van-cell title="保证金余额（元）" :value="utils.moneyFormat()" />
            <van-cell title="历史盈余金额（元）" :value="utils.moneyFormat()" />
          </van-cell-group>
        </van-tab>
        <van-tab name="quota" title="额度信息">
          <van-cell-group title="本次额度信息">
            <van-cell title="产品类型">
              <van-checkbox-group v-model="quotaDetail.productType" disabled class="detail-checkbox-group">
                <van-checkbox v-for="item in constants.ProjectProductType" :key="item.value" :name="item.value">
                  {{ item.label }}
                </van-checkbox>
              </van-checkbox-group>
            </van-cell>
            <van-cell title="合作额度(万元)" :value="utils.moneyFormat(quotaDetail.preQuota)" />
            <van-cell title="调整额度至(万元)" :value="utils.moneyFormat(quotaDetail.quota)" />
            <van-cell title="合作期限(月)" :value="utils.isEffectiveCommon(quotaDetail.term)" />
            <van-cell title="额度起止日" :value="`${utils.dateFormat(quotaDetail.quotaDateStart)} - ${utils.dateFormat(quotaDetail.quotaDateEnd)}`" />
            <van-cell title="调整额度理由" :value="utils.isEffectiveCommon(quotaDetail.reason)" />
          </van-cell-group>
        </van-tab>
        <van-tab name="file" title="相关附件">
          <attachment-list module-name="QUOTA" :business-id="$route.query.id" />
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit page-key="credit_lmt_adjusting" :business-key="$route.query.id" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.id,motif: accessDetail.orgShortName}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
import AttachmentList from '@/components/attachment-list'
// import fileList from '@/components/file-list'
export default {
  components: {
    AttachmentList,
    WorkflowAudit,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      accessDetail: {},
      quotaDetail: {}
    }
  },
  created () {
    this.getCompanyDetail()
    this.getQuotaDetail()
  },
  methods: {
    getCompanyDetail () {
      this.utils.loading()
      this.api.task.companyAccess.enterpriseModifyDetail(this.$route.query.enterpriseId).then(result => {
        this.accessDetail = result.data.value
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getQuotaDetail () {
      this.api.task.companyAccess.quotaInformationDetail(this.$route.query.id).then(result => {
        const data = result.data.value
        if (data && data.productType) {
          data.productType = data.productType.split(',')
        } else {
          data.productType = []
        }
        this.quotaDetail = data
      })
    }
  }
}
</script>

<style scoped>

</style>
