var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "企业客户信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "企业全称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业简称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgShortName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "成立日期",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.establishDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "统一社会信用代码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册资本（万元）",
                          value: _vm.utils.moneyFormat(
                            _vm.accessDetail.orgRegCap
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.orgContactPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "注册地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.accessDetail.orgRegProvince,
                            _vm.accessDetail.orgRegCity,
                            _vm.accessDetail.orgRegXq,
                            _vm.accessDetail.orgRegDetail
                          ])
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "办公地址",
                          value: _vm.utils.newProvinceCityFormatter([
                            _vm.accessDetail.orgWorkProvince,
                            _vm.accessDetail.orgWorkCity,
                            _vm.accessDetail.orgWorkXq,
                            _vm.accessDetail.orgWorkDetail
                          ])
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "企业法定代表人信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人姓名",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件类型",
                          value: _vm.utils.statusFormat(
                            _vm.accessDetail.businessSnType,
                            "typeCertificateType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "电子邮箱",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessEmail
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "法定代表人联系电话",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.bizPhone
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "证件号码",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.accessDetail.businessSn
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "企业录入人",
                          value: _vm.utils.isEffectiveCommon()
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "van-cell-group",
                    { attrs: { title: "账户余额" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "保证金余额（元）",
                          value: _vm.utils.moneyFormat()
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "历史盈余金额（元）",
                          value: _vm.utils.moneyFormat()
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "quota", title: "额度信息" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "本次额度信息" } },
                    [
                      _c(
                        "van-cell",
                        { attrs: { title: "产品类型" } },
                        [
                          _c(
                            "van-checkbox-group",
                            {
                              staticClass: "detail-checkbox-group",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.quotaDetail.productType,
                                callback: function($$v) {
                                  _vm.$set(_vm.quotaDetail, "productType", $$v)
                                },
                                expression: "quotaDetail.productType"
                              }
                            },
                            _vm._l(_vm.constants.ProjectProductType, function(
                              item
                            ) {
                              return _c(
                                "van-checkbox",
                                {
                                  key: item.value,
                                  attrs: { name: item.value }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("van-cell", {
                        attrs: {
                          title: "合作额度(万元)",
                          value: _vm.utils.moneyFormat(_vm.quotaDetail.preQuota)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "调整额度至(万元)",
                          value: _vm.utils.moneyFormat(_vm.quotaDetail.quota)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合作期限(月)",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.quotaDetail.term
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "额度起止日",
                          value:
                            _vm.utils.dateFormat(
                              _vm.quotaDetail.quotaDateStart
                            ) +
                            " - " +
                            _vm.utils.dateFormat(_vm.quotaDetail.quotaDateEnd)
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "调整额度理由",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.quotaDetail.reason
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "file", title: "相关附件" } },
                [
                  _c("attachment-list", {
                    attrs: {
                      "module-name": "QUOTA",
                      "business-id": _vm.$route.query.id
                    }
                  })
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  _c("workflow-audit", {
                    attrs: {
                      "page-key": "credit_lmt_adjusting",
                      "business-key": _vm.$route.query.id,
                      "task-id": _vm.$route.query.taskId,
                      "proc-inst-id": _vm.$route.query.procInstId,
                      variable: {
                        businessId: _vm.$route.query.id,
                        motif: _vm.accessDetail.orgShortName
                      }
                    },
                    on: {
                      confirmAudit: function($event) {
                        return _vm.utils.backReturn(
                          _vm.$route.query.messageType
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }